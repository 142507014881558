import { useQuery } from '@tanstack/react-query'
import { API_URL } from '../const'
import axios from 'axios'
import { Geocontext, ScrapingGeocontext } from '../types'

export async function getGeocontexts(
  signal?: AbortSignal
) {
  return (
    await axios.get(`${API_URL}/geo-contexts/`, {
      signal,
    })
  ).data as Geocontext[]
}

export function useGeocontexts() {
  return useQuery(
    ['geo-contexts'],
    ({ signal }) => getGeocontexts(signal),
    {
      keepPreviousData: true,
      suspense: false,
    }
  )
}

export async function getLastScrapingGeocontext(id: number) {
  return (
    await axios.get(`${API_URL}/geo-contexts/${id}/scraping/`)
  ).data as ScrapingGeocontext
}

export function useLastScrapingGeocontext(id: number) {
  return useQuery(
    ['geo-contexts', id, 'scraping'],
    () => getLastScrapingGeocontext(id),
    {
      keepPreviousData: true,
      suspense: false,
      enabled: !!id,
    }
  )
}